import { Link } from "react-router-dom";
import styles from "../../page.module.css";
import logo from "../../assets/ucg-logo.png";
import { InputPrompt } from "../../componentes/InputPrompt/InputPrompt";
import { ChatContentArea } from "../../componentes/ChatContent/ChatContentArea";
import { useEffect } from "react";

export const Marcia = () => {
  useEffect(()=>{
    document.title = `marcIA`;
  })
  return (
    <main className={styles.main}>
      <header>
        <Link to="/" className="chatLogo">
          <img src={logo} alt="" />
        </Link>
        {
          // @ts-ignore
          <Link to={-1}>Atrás</Link>
        }
      </header>
      <ChatContentArea />
      <InputPrompt />
      <p className="disclaimer">AI desarrollada por ®UniversidadCasaGrande para ayudar a todos los jóvenes a conectar con  sus intereses con su futuro - Versión Beta.</p>
    </main>
  );
};
