import { useEffect, useRef } from "react";
import styles from "../../page.module.css";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import iconoUsuario from "../../assets/icono-usuario.png";
import iconoMarcia from "../../assets/icono-marcia.png";
import { useAppContext } from "../../context/context";

export const ChatContentArea = () => {
  const { chats, disableInput } = useAppContext();
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats]); //

  const renderedChats = chats.map((itemChat, index) => (
    <div className={styles.promptContainer} key={index}>
      <div className={styles.promptAvatar}>
        <img
          src={itemChat.role === "m" ? iconoMarcia : iconoUsuario}
          alt=""
          height={40}
          width={40}
        />
        <h2>{itemChat.role === "m" ? "marcIA:" : "Tú:"}</h2>
      </div>
      <div className={itemChat.role === "m" ? styles.promptMarciaContent : styles.promptUserContent}>
        <Markdown remarkPlugins={[remarkGfm]}>{itemChat.parts}</Markdown>
      </div>
    </div>
  ));
  return (
    <div ref={chatContainerRef} className={styles.chatContainer}>
      <div>
        {renderedChats}

        {disableInput === false ? null : (
          <div className="loading">
            <img src={iconoMarcia} alt="" height={40} width={40} />
            <div className="bar-container">
              <div className="loading-bar" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
