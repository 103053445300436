import "./App.css";
import AppRouter from "./Router/AppRouter";
import { FondoBg } from "./componentes/Fondobg/Fondobg";
import { ContextProvider } from "./context/context";

function App() {
  return (
    <ContextProvider>
      <FondoBg />
      <AppRouter />
    </ContextProvider>
  );
}

export default App;
