import { reducerTypes, Action, State } from "../types/index";
import { ChatItems } from "../models/ChatItemsModel";

export const reducer=(state :State, action: Action)=>{
const {type, payload} = action;

switch(type){
    case reducerTypes.agregarChat:
        return agregarChat(state, payload);
    case reducerTypes.actualizarPrompt:
        return actualizarPrompt(state, payload);
        case reducerTypes.inputDisable: 
        return bloquearInput(state, payload);
    default: 
        return state;
    }
}

const agregarChat =(state : State, payload: ChatItems)=>{
    return {...state, chats:[...state.chats, payload], prompt:"" }
}

const actualizarPrompt=(state: State, payload: string )=>{
    return {...state, prompt: payload}
};
const bloquearInput=(state: State, payload: boolean)=>{
    return {...state, disableInput: payload}
}