import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "../pages/Home/HomePage";
import { SobreMarcia } from "../pages/sobre-marcia/SobreMarcia";
import { Marcia } from "../pages/Marcia/Marcia";
const  AppRouter: React.FC=()=>(
<Router>
    <Routes>
        <Route path="/" Component={HomePage}/>
        <Route path="/sobre-marcia" Component={SobreMarcia}/>
        <Route path="/marcia" Component={Marcia}/>
    </Routes>
</Router>);
export default AppRouter;