import { Link } from "react-router-dom";
import styles from "./sobremarcia.module.css";
import logo from "../../assets/ucg-logo.png";
import { useEffect } from "react";

export const SobreMarcia = () => {
  useEffect(() => {
    document.title = `marcIA - ¿Qué es?`;
  })
  return (
    <main>
      <header>
        <Link to="/" className="chatLogo">
          <img src={logo} alt="" />
        </Link>
        {
          // @ts-ignore
          <Link to={-1}>Atrás</Link>
        }
      </header>
      <section className={styles.sectionSobre}>
        <div className={styles.titleContainer}>
          <h1>Sobre marc<span className="text-a">IA</span></h1>
          <Link className="outlined-button" to="../marcia">
            INICIAR
          </Link>
        </div>
        <div className={styles.texto}>
          <p>
            marcIA es nuestra inteligencia artificial para guiar a quienes no
            saben aún lo que les gusta y no han elegido su camino. A quienes sí
            saben lo que les gusta, pero no saben si se puede vivir de eso, a
            quienes dudan de si son talentosos o a quienes simplemente les
            asusta que eso que les apasiona desde siempre, se vuelva obsoleto en
            el futuro.
          </p>
          <p>Para esos jóvenes que se están graduando y todavía siguen averiguando cuál es el siguiente paso a dar.</p>
          <p>marcIA, les da la bienvenida</p>
        </div>
      </section>
    </main>
  );
};
