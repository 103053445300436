import { useEffect, useState } from "react";
import logo from "../../assets/ucg-logo.png";
import intro from "../../assets/parte1.mp4";
import loop from "../../assets/parte2.mp4";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [logoState, setLogoState] = useState("");
  const [aState, setAState] = useState("");
  const [index, setIndex] = useState(0);
  const iType = document.getElementById("iType");
  useEffect(() => {
    document.title = `marcIA - UCG`;
    const interval = setInterval(
      () => {
        if (index < "marc".length) {
          setLogoState((prev) => prev + "marc"[index]);
          setIndex((prev) => prev + 1);
        } else {
          clearInterval(interval);
          setAState("A");
          if (iType !== null) {
            iType!.remove();
          }


        }
      },
      index === 0 ? 2000 : 100
    );
    return () => {
      clearInterval(interval);
    };
  }, [index]);

  return (
    <main>
      <header className="intro">
        <img src={logo} className="App-logo" alt="logo" />
        <Link to="./sobre-marcia">¿Qué es?</Link>
      </header>
      <div className="home-layout">
        <div style={{ height: "10px" }}></div>
        <div className="main-area">
          <div className="logo-container">
            {logoState}
            <div className="text-anim" id="iType">I</div>
            <div className="text-anim-back">I</div>
            <div className="text-a">{aState}</div>
          </div>
          <p className="subtitle">
            UNA INTELIGENCIA ARTIFICIAL PARA LOS QUE DUDAN QUÉ ESTUDIAR.
          </p>
          <Link className="outlined-button intro" style={{marginTop:"40px"}} to="./marcia">
            INICIAR
          </Link>
        </div>
        <p className="disclaimer-home">
          AI desarrollada por ®UniversidadCasaGrande para ayudar a todos los
          jóvenes a conectar con sus intereses con su futuro - Versión Beta.
        </p>
      </div>
    </main>
  );
};

export default HomePage;
