  import { ChatItems } from "../models/ChatItemsModel";

export enum reducerTypes{agregarChat, actualizarPrompt, inputDisable}
export interface State {
    prompt: string;
    chats: ChatItems[];
    disableInput: boolean;
  }
  
  export type Action =
    {
        type: reducerTypes.agregarChat;
        payload: ChatItems;
      }
    | {
        type: reducerTypes.actualizarPrompt;
        payload: string;
      }
      |{
        type: reducerTypes.inputDisable;
        payload: boolean;
      };