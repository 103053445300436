import { useAppContext, useAppProvider } from "../../context/context";
import { historial, runChat } from "../../functions/functions";
import { ChatItems } from "../../models/ChatItemsModel";
import { reducerTypes } from "../../types";
import styles from "../../page.module.css";

export const InputPrompt = () => {
  const dispatch = useAppProvider();
  const { prompt, chats, disableInput } = useAppContext();

  function clickSugestPrompt(text: string) {
    handlePromptUpdate(text);
    if (sendSugest.length > 0) {
      sendSugest(text);
    }else{
      console.log("no he actualizado el prompt a: " + text)
    }
  }

  const handlePromptUpdate = (text: string) => {
    dispatch({ type: reducerTypes.actualizarPrompt, payload: text });
    return prompt;
  };

  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (prompt.length > 0) {
        await sendChat();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: reducerTypes.actualizarPrompt,
      payload: event.target.value,
    });
  };
  async function sendChat() {
    dispatch({ type: reducerTypes.inputDisable, payload: true });

    if (prompt.length > 0) {
      const promptChatItem: ChatItems = { role: "u", parts: prompt };
      dispatch({ type: reducerTypes.agregarChat, payload: promptChatItem });
      const respuesta = await runChat(prompt);
      historial.push({
        role: "user",
        parts: [{ text: prompt }],
      });
      const responseChatItem: ChatItems = { role: "m", parts: respuesta };
      historial.push({
        role: "model",
        parts: [{ text: respuesta }],
      });
      dispatch({ type: reducerTypes.agregarChat, payload: responseChatItem });
    } else {
      console.log("no ha ingresado un texto");
    }
    dispatch({ type: reducerTypes.inputDisable, payload: false });

    return;
  }
  async function sendSugest(text: string) {
    dispatch({ type: reducerTypes.inputDisable, payload: true });

    if (text.length > 0) {
      const promptChatItem: ChatItems = { role: "u", parts: text };
      dispatch({ type: reducerTypes.agregarChat, payload: promptChatItem });
      const respuesta = await runChat(text);
      historial.push({
        role: "user",
        parts: [{ text: text }],
      });
      const responseChatItem: ChatItems = { role: "m", parts: respuesta };
      historial.push({
        role: "model",
        parts: [{ text: respuesta }],
      });
      dispatch({ type: reducerTypes.agregarChat, payload: responseChatItem });
    } else {
      console.log("no ha ingresado un texto");
    }
    dispatch({ type: reducerTypes.inputDisable, payload: false });

    return;
  }

  return (
    <>
      {chats.length === 0 ? (
        <>
          <b>¿Qué cosas del mundo te incomodan hoy? Hablemos</b>
          <div className={styles.wrapper}>
            <button
              className={styles.cardSugerencia}
              onClick={async () =>
                clickSugestPrompt(
                  "Me interesa una carrera, pero no sé qué tipo de trabajo puedo esperar en esta carrera"
                )
              }
            >
              <b>Me interesa una carrera</b>
              <p>
                pero no sé qué tipo de trabajo puedo esperar en esta carrera
              </p>
            </button>
            <button
              className={styles.cardSugerencia}
              onClick={() =>
                clickSugestPrompt(
                  "Hay temas del mundo que molestan, ¿Cómo puedo resolverlas?"
                )
              }
            >
              <b>Hay temas del mundo que molestan</b>
              <p>¿Cómo puedo resolverlas?</p>
            </button>
            <button
              className={`${styles.cardSugerencia} ${styles.darkSugerencia}`}
              onClick={() =>
                clickSugestPrompt(
                  "No sé a que dedicarme, Solo tengo una idea de lo que me gusta"
                )
              }
            >
              <b>No sé a que dedicarme</b>
              <p>Solo tengo una idea de lo que me gusta</p>
            </button>
            <button
              className={`${styles.cardSugerencia} ${styles.darkSugerencia}`}
              onClick={() =>
                clickSugestPrompt(
                  "¿Cómo puedo hacer un impacto a la comunidad?, Desde la carrera que me gusta"
                )
              }
            >
              <b>¿Cómo puedo hacer un impacto a la comunidad?</b>
              <p>Desde la carrera que me gusta</p>
            </button>
          </div>
        </>
      ) : null}
      <div className={styles.inputContainer}>
        <input
          disabled={disableInput}
          id="inputPrompt"
          value={prompt}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          type="text"
          name="inputPrompt"
          placeholder="Escríbele aquí a marcIA"
          className={styles.inputPrompt}
        />
        <button
          className={`icon-button ${styles.sendButton}`}
          onClick={() => {
            sendChat();
          }}
        >
          <span className="material-symbols-outlined">send</span>
        </button>
      </div>
    </>
  );
};
