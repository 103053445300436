"use client";
import React, {
  createContext,
  useContext,
  useReducer,
} from "react";
import { reducer } from "./reducers";
import { Action, State } from "../types";
// import { Action, State } from "@types";
const Context = createContext<State | undefined>(undefined);
const Provider = createContext<React.Dispatch<Action> | undefined>(undefined);

export const ContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const estadoInicial: State = {
    prompt: "",
    chats: [],
    disableInput: false,
  };
  const [state, dispatch] = useReducer(reducer, estadoInicial);
  return (
    <Context.Provider value={state}>
      <Provider.Provider value={dispatch}>{children}</Provider.Provider>
    </Context.Provider>
  );
};

export const useAppContext = (): State => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};
export const useAppProvider = (): React.Dispatch<Action> => {
  const context = useContext(Provider);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};
